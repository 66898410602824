<template>
  <b-modal
    v-model="onControl"
    :title="'Replies Value List'"
    title-tag="h3"
    modal-class="modal-primary"
    size="lg"
    scrollable
    hide-footer
    @hidden="close"
  >
    <filter-slot

        :filter="filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        no-visible-principal-filter="true"
        @reload="$refs['refListLeadsByValue'].refresh()"
    >
    <b-table
        id="refListLeadsByValue"
        slot="table"
      ref="refListLeadsByValue"
      no-border-collapse

      :fields="fields"
      show-empty
      no-provider-filtering
      sticky-header="50vh"
      primary-key="id"
      responsive="sm"
      :items="getLeadsValueData"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :current-page="paginate.currentPage"
      :per-page="paginate.perPage"
      :filter="searchInput"
      :busy.sync="isBusy"
        class="table-scroll-per"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>

      <template #cell(lead_name)="data">
        <router-link
            :class="textLink"
            :to="`/${routeModule}/${data.item.lead_id}`"
            target="_blank"
        >{{ data.item.nickname
            ? data.item.nickname
            : data.item.not_nickname }}</router-link>
        <br>
        <span>{{ data.item.lead_name }}</span>
      </template>
      <template #cell(multiply)="data">
        <span class="text-center d-flex justify-content-center"> {{ data.item.multiply  ?  data.item.multiply : 1 }}</span>
      </template>

      <template #cell(created_at)="data">
        <small>{{ data.item.owner }}</small>
        <br />
        <small>{{ data.item.created_at | myGlobalDay }}</small>
      </template>

      <template #custom-foot>
        <b-tr class="bg-dark "  >
          <b-th >
            <span class="title" style="font-size: 12px">SUBTOTAL</span>
            <br/>
            <br/>
            <br/>
            <span class="title" style="font-size: 12px">TOTAL</span>

          </b-th>



          <b-th
              class="footer-dark"
              style="max-width: 200px; width: 150px"
          >
            <div class="footer-span w-100">
                      <span class=" font-small-3  "> {{
                          sub_total_value
                        }} </span>

            </div>
            <br/>
            <div class="footer-span w-100">
                      <span class=" font-small-3  "> {{
                          total_value
                        }} </span>

            </div>


          </b-th>
          <b-th />



        </b-tr>


      </template>
    </b-table>
    </filter-slot>
  </b-modal>
</template>
<!--rgb(125, 195, 84)-->
<script>
import RoiReportsService from '@/views/commons/components/roi-reports/roi-reports.service'

export default {
  props: {
    flyerId: null,
    route: null,
    dateFrom: null,
    dateTo: null,
  },
  data() {
    return {
      filter: [],
      onControl: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      itemsSales: [],
      fields: [
        { key: 'lead_name', label: 'Lead' },
        { key: 'multiply', label: 'Replies', thStyle: { textAlign: 'center' } },
        { key: 'created_at', label: 'Created by' },
      ],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      selectedItem: [],
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: 'name',
      sortDesc: true,
      searchInput: '',
      textType: '',
      percents: [],
      randomColorLight: '',
      randomColorDark: '',
      total_value:null,
      sub_total_value:null
    }
  },
  computed: {
    routeModule() {
      return this.route
    },
  },
  async created() {
    this.onControl = true
    await this.getLeadsValueData()
  },
  methods: {
    close() {
      this.$emit('close')
    },

    // eslint-disable-next-line consistent-return
    async getLeadsValueData() {
      try {
        this.isBusy = true
        const data = await RoiReportsService.getLeadsValueByFlyerId({
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          orderby: 6,
          order: 'desc',

          date_flyer_from: this.dateFrom,
          date_flyer_to: this.dateTo,
          idFlyer: this.flyerId,
        })

        this.isBusy = false
        this.itemsSales = data.data.data
        if (this.itemsSales.length > 0) {
          this.total_value = this.itemsSales[0].total_value
          this.sub_total_value = this.itemsSales[0].subtotal


        }

        this.startPage = data.data.from
        this.paginate.currentPage = data.data.current_page
        this.paginate.perPage = data.data.per_page
        this.nextPage = this.startPage + 1
        this.endPage = data.data.last_page
        this.totalData = data.data.total
        this.totalRows = data.data.total
        this.toPage = data.data.to

        return this.itemsSales
      } catch (error) {
        this.showErrorSwal(error)
        this.isBusy = false
      }
    },
  },
}
</script>

<style scoped>
.schedule {
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 7px;
}
.table-scroll-per tfoot tr,
.table-scroll-per tfoot th{
  position: sticky !important;
  bottom: 0 !important;



}
</style>
