<template>
  <b-sidebar
    ref="sidebarRoi"
    v-model="isFiltersActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    header-class="pt-1"
    shadow
    backdrop
    right
    @hidden="close"
  >
    <template #header>
      <div class="d-flex justify-content-between align-items-center w-100">
        <span>
          <h3>Advanced Search</h3>
        </span>
        <span v-b-toggle.sidebar-right class="cursor-pointer" @click="closeB">
          <tabler-icon icon="XIcon" size="20" />
        </span>
      </div>
    </template>
    <b-container class="mt-2">
      <b-row>
        <b-col cols="12" class="mb-md-0 mb-2">
          <b-form-group label="Select Date:" label-for="from">
            <b-form-datepicker
              v-model="filters.date"
              locale="en"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              placeholder="Date"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="mb-md-0 mb-2">
          <b-form-group label="Programs" label-for="from">
            <v-select
              v-model="filters.program"
              :options="filtersData.programs"
              :reduce="(el) => el.value"
              @change="changeProgram"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <template #footer>
      <b-container>
        <b-row class="d-flex p-1 float-right">
          <b-button variant="info" @click="resetFiltersButtons">
            Reset
          </b-button>
          <b-button variant="primary" class="ml-1" @click="sideBarSearch">
            Search
          </b-button>
        </b-row>
      </b-container>
    </template>
  </b-sidebar>
</template>

<script>
import vSelect from "vue-select";
import moment from "moment";

export default {
  components: {
    vSelect,
  },
  props: {
    filters: {
      type: Object,
    },
    filtersData: {
      type: Object,
    },
  },
  data() {
    return {
      isFiltersActive: false,
    };
  },
  watch: {
    "filters.program": function () {
      this.changeProgram();
    },
  },
  created() {
    this.filters.date = moment(Date.now()).format("YYYY-MM-DD");
  },
  mounted() {
    this.isFiltersActive = true;
  },
  computed: {},
  methods: {
    resetFiltersButtons() {
      this.filters.date = "";
      this.filters.program = "";
    },
    sideBarSearch() {
      this.$emit("onSearch");
      this.close(true);
    },
    changeProgram() {
      this.$emit("onChangeProgram");
    },
    close() {
      this.$emit("close");
    },
    closeB() {
      this.$refs.sidebarRoi.hide();
    },
  },
};
</script>

<style scoped>
</style>
