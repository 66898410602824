<template>
  <b-modal
    v-model="onControl"
    :title="'Motives for ' + textType"
    title-tag="h3"
    modal-class="modal-primary"
    size="lg"
    scrollable
    hide-footer
    @hidden="close"
  >
    <filter-slot
      :filter="[]"
      :filter-principal="{}"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      no-visible-principal-filter="true"
      @reload="getReportRepliesMotivesData"
    >
      <template #table>
        <b-table
          ref="refCategoryClients"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="itemsMotives"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(lead)="data">
            <router-link
              :class="textLink"
              :to="`/${routeModule}/${data.item.lead_id}`"
              target="_blank"
              >{{
                data.item.nickname ? data.item.nickname : data.item.not_nickname
              }}</router-link
            >
            <br />
            <span>{{ data.item.lead_name }}</span>
          </template>

          <template #cell(motive)="data">
            {{ data.item.motive_desc }}
          </template>
          <template #cell(created_at)="data">
            {{ data.item.created_by }}
            <br />
            {{
              (data.item.updated_at
                ? data.item.updated_at
                : data.item.created_at) | myGlobalDay
            }}
          </template>
        </b-table>
      </template>
    </filter-slot>

    <div
      :class="[percents.length > 2 ? 'justify-content-between' : '']"
      class="d-flex w-100 flex-wrap"
    >
      <div
        v-for="(item, index) in percents"
        :key="index"
        style="width: 30%; margin-top: 0.8rem"
        :class="[
          isDarkSkin ? 'opacity-50' : 'text-white',
          percents.length > 2 ? 'justify-content-between' : 'mr-1',
        ]"
        class="schedule text-center mt-1 text-white"
        :style="
          isDarkSkin
            ? 'background: ' + item.color_dark + '!important'
            : 'background: ' + item.color_light + '!important'
        "
      >
        <span v-b-tooltip.hover.top="item.description"
          ><span class="font-weight-bolder"
            >{{
              item.description.length > 18
                ? item.description.substring(0, 18) + ".."
                : item.description
            }}:
          </span>
          {{ parseFloat(item.percent).toFixed(2) }}%</span
        >
      </div>
    </div>
  </b-modal>
</template>
<!--rgb(125, 195, 84)-->
<script>
import RoiReportsService from "@/views/commons/components/roi-reports/roi-reports.service"

export default {
  props: {
    type: {
      type: Number,
      default: null,
    },
    flyerId: null,
    route: null,
    dateFrom: null,
    dateTo: null,
  },
  data() {
    return {
      onControl: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      itemsMotives: [],
      fields: [
        { key: "lead", label: "Lead" },
        { key: "motive", value: "Motive" },
        { key: "created_at", label: "Updated" },
      ],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      selectedItem: [],
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: "name",
      sortDesc: true,
      searchInput: "",
      textType: "",
      percents: [],
      randomColorLight: "",
      randomColorDark: "",
    }
  },
  computed: {
    routeModule() {
      return this.route
    },
  },
  async created() {
    this.onControl = true
    if (this.type === 1) {
      this.textType = "no answers"
    } else if (this.type === 2) {
      this.textType = "no mobiles"
    } else if (this.type === 3) {
      this.textType = "no appointment to filter"
    } else if (this.type === 4) {
      this.textType = "no appointment to seller"
    } else if (this.type === 5) {
      this.textType = "no final sale"
    } else if (this.type === 6) {
      this.textType = "no answer to seller"
    } else if (this.type === 7) {
      this.textType = "no answer to filter"
    }
    await this.getReportRepliesMotivesData()
  },
  methods: {
    close() {
      this.$emit("close")
    },
    async getReportRepliesMotivesData() {
      try {
        this.isBusy = true
        const params = {
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          type_id: this.type,
          flyer_id: this.flyerId,
          date_from: this.dateFrom,
          date_to: this.dateTo,
          show_no_answer: null,
        }
        if (this.type === 1 || this.type === 2 || this.type === 3) {
          await this.getReportRepliesData(params)
        } else if (
          this.type === 4 ||
          this.type === 5 ||
          this.type === 6 ||
          this.type === 7
        ) {
          await this.getReportEventData(params)
        }
        if (this.itemsMotives.length > 0) {
          this.percents = JSON.parse(this.itemsMotives[0].percents)
          for (const element of this.percents) {
            await this.setColor(element)
          }
        }
      } catch (error) {
        this.isBusy = false
        this.showErrorSwal(error)
      }
    },

    async getReportRepliesData(params) {
      if (this.type === 1 || this.type === 2 || this.type === 3) {
        const data = await RoiReportsService.getReportRepliesMotives(params)
        if (data.status === 200) {
          this.itemsMotives = data.data.data
          this.isBusy = false
          this.startPage = data.data.from
          this.paginate.currentPage = data.data.current_page
          this.paginate.perPage = data.data.per_page
          this.totalRows = data.data.total
          this.toPage = data.data.to
        }
      }
    },

    async getReportEventData(params) {
      if (this.type === 5 || this.type === 4) {
        params.show_no_answer = 1
      } else if (this.type === 6) {
        params.show_no_answer = 2
        params.type_id = 5
      } else if (this.type === 7) {
        params.show_no_answer = 2
        params.type_id = 4
      }
      const data = await RoiReportsService.getReportEventMotives(params)
      if (data.status === 200) {
        this.itemsMotives = data.data.data
        this.isBusy = false
        this.startPage = data.data.from
        this.paginate.currentPage = data.data.current_page
        this.paginate.perPage = data.data.per_page
        this.totalRows = data.data.total
        this.toPage = data.data.to
      }
    },

    async setColor(element) {
      const r = Math.floor(Math.random() * 361)
      const g = Math.floor(Math.random() * 101)
      this.randomColorLight = `hsl(${r}, ${g}%, 60%)`
      this.randomColorDark = `hsl(${r}, ${g}%, 40%)`
      element.color_light = this.randomColorLight
      element.color_dark = this.randomColorDark
    },
  },
}
</script>

<style scoped>
.schedule {
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 7px;
}
</style>
