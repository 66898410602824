<template>
  <b-modal
    v-model="onControl"
    :title="'Sales List Recovery'"
    title-tag="h3"
    modal-class="modal-primary"
    size="lg"
    scrollable
    hide-footer
    @hidden="close"
  >
    <filter-slot
      :filter="[]"
      :filter-principal="{}"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      no-visible-principal-filter="true"
      @reload="$refs['refCategoryClients'].refresh()"
    >
      <b-table
        id="refCategoryClients"
        ref="refCategoryClients"
        no-border-collapse

        slot="table"

        class="position-relative"
        :fields="fields"
        show-empty
        no-provider-filtering
        sticky-header="50vh"
        primary-key="id"
        responsive="sm"
        :items="getReportRepliesMotivesData"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :filter="searchInput"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(nickname)="data">
          <router-link
            :class="textLink"
            :to="`/${routeModule}/${data.item.lead_id}`"
            target="_blank"
          >{{
            data.item.nickname ? data.item.nickname : data.item.not_nickname
          }}</router-link>
          <br>
          <span>{{ data.item.not_nickname }}</span>
        </template>
        <template #cell(amount)="data">
          <span>$ {{ data.item.amount | currency }}</span>
        </template>
      </b-table>
    </filter-slot>
    <!-- <div
      :class="[(percents.length>2? 'justify-content-between' : '')]"
      class="d-flex w-100 flex-wrap"
    >
      <div
        v-for="(item, index) in percents"
        :key="index"
        style="width: 30%; margin-top: 0.8rem"
        :class="[(isDarkSkin? 'opacity-50' : 'text-white'), (percents.length>2? 'justify-content-between' : 'mr-1')]"
        class="schedule text-center mt-1  text-white"
        :style="isDarkSkin?'background: ' + item.color_dark + '!important' : 'background: ' + item.color_light + '!important'"
      >
        <span v-b-tooltip.hover.top="item.description"><span class="font-weight-bolder">{{ item.description.length > 18 ? item.description.substring(0, 18) + '..' : item.description }}:
        </span>  {{ parseFloat(item.percent).toFixed(2) }}%</span>
      </div>
    </div> -->
  </b-modal>
</template>
<!--rgb(125, 195, 84)-->
<script>
import RoiReportsService from '@/views/commons/components/roi-reports/roi-reports.service'

export default {
  props: {
    flyerId: null,
    route: null,
    dateFrom: null,
    dateTo: null,
  },
  data() {
    return {
      onControl: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      itemsSales: [],
      fields: [
        { key: 'nickname', label: 'Client' },
        { key: 'amount', value: 'Initial Payment', label: 'Initial Payment' },
        { key: 'seller_name', label: 'Seller', label: 'Seller' },
        { key: 'program', label: 'Program', label: 'Program' },
      ],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      selectedItem: [],
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: 'name',
      sortDesc: true,
      searchInput: '',
      textType: '',
      percents: [],
      randomColorLight: '',
      randomColorDark: '',
    }
  },
  computed: {
    routeModule() {
      return this.route
    },
  },
  async created() {
    this.onControl = true

  },
  methods: {
    close() {
      this.$emit('close')
    },

    async getReportRepliesMotivesData() {
      try {
        this.isBusy = true
        const data = await RoiReportsService.getReportRepliesSalesRecovery({
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          orderby: 6,
          order: 'desc',
          date_flyer_from: this.dateFrom,
          date_flyer_to: this.dateTo,
          idFlyer: this.flyerId,
        })
        this.itemsSales = data.data.data

        this.startPage = data.data.from
        this.paginate.currentPage = data.data.current_page
        this.paginate.perPage = data.data.per_page
        this.nextPage = this.startPage + 1
        this.endPage = data.data.last_page
        this.totalData = data.data.total
        this.totalRows = data.data.total
        this.toPage = data.data.to
        this.isBusy = false
        return this.itemsSales
      } catch (error) {
        this.showErrorSwal(error)
        this.isBusy = false
      }
    },
  },
}
</script>

<style scoped>
.schedule {
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 7px;
}
</style>
